import { http } from 'utils/service/http'

import { Survey } from './types'

export const surveyApi = {
  giveConsent() {
    return http.post(`invitation/consent`, {})
  },
  update(survey: Partial<Survey>) {
    return http.patch(``, survey)
  },
  getQuestions() {
    return http.get('questions')
  },
  getInvitation() {
    return http.get('invitation')
  },
  getAssessments() {
    return http.get(`assessments`)
  },
}
